<template>
  <div>
    <div class="row">
      <retry-notification :show="error" @retry="updateData()" />
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-collapse withBackground>
          <span slot="header">{{ $t("layout.filters.label") }}</span>
          <template slot="body">
            <filters
              :loading="loading"
              :actions="filterActions"
              @filter="filterData"
              @clear-filters="filterData(null)"
            />
          </template>
        </va-collapse>
      </div>
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-card :title="$t('mti.index')">
          <remote-table
            class="table-hover table-striped"
            :columns="tableFields"
            :data="tableData"
            :loading="loading"
            :search="searchOptions"
            :pagination="pagination"
            :params="serverParams"
            :queries="serverParams.queries"
            sort-field="name"
            sort-type="asc"
            crud-links="mti"
            controller="mti"
            @update-data="updateData"
            @search-data="searchByName"
            @delete-item="tryDelete"
            @download-item="prepareDownload"
          />
        </va-card>
      </div>
    </div>
    <div class="flex xs12">
      <va-card :title="$t('info.mti.status')">
        <ubication-bar
          selection="district"
          :loading="loading"
          @submit="prepareDownloadTeamStatus"
        />
      </va-card>
    </div>
  </div>
</template>

<script>
const Filters = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/Filters");
const RemoteTable = () =>
  import(
    /* webpackPrefetch: true */ "@/components/extras/DataTables/RemoteTable"
  );
const UbicationBar = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/Bars/Ubication");
export default {
  name: "mti-index",
  components: {
    Filters,
    RemoteTable,
    UbicationBar,
  },
  data() {
    return {
      error: false,
      tableData: [],
      pagination: {},
      searchQuery: "",
      loading: false,
      searchOptions: {
        enabled: true,
        trigger: "enter",
        placeholder: this.$t("tables.actions.search"),
      },
      serverParams: {
        queries: "",
      },
      filterActions: ["regions", "countries", "districts", "name", "team"],
    };
  },
  computed: {
    tableFields() {
      return [
        {
          name: "team.code",
          title: this.$t("tables.headings.team"),
          sortField: "Teams.code",
        },
        {
          name: "name",
          title: this.$t("tables.headings.name"),
          sortField: "name",
        },
        {
          name: "team.district.country.name",
          title: this.$t("tables.headings.country"),
          sortField: "Countries.name",
          callback: this.translatedName,
        },
        {
          name: "team.district.name",
          title: this.$t("tables.headings.district"),
          sortField: "Districts.name",
          callback: this.translatedName,
        },
        {
          name: "minister.name",
          title: this.$t("tables.headings.mti_minister"),
          sortField: "Ministers.name",
        },
        {
          name: "__slot:actions",
          visible: this.tableData.length > 0,
          dataClass: "text-right",
          width: "20%",
        },
      ];
    },
  },
  methods: {
    translatedName(name) {
      return this.$t(name);
    },
    filterData(filters) {
      if (!filters) {
        this.serverParams.queries = "";
        return;
      }

      let queries = "";

      if (filters.country) {
        queries += "&country=" + filters.country;
      }
      if (filters.district) {
        queries += "&district=" + filters.district;
      }

      if (filters.region) {
        if (filters.country || filters.district) {
          queries += "";
        } else {
          queries += "&region_id=" + filters.region;
        }
      }

      if (filters.team) {
        queries += "&team=" + filters.team;
      }
      if (filters.name) {
        queries += "&q=" + filters.name;
      }

      this.serverParams.queries = queries;
    },
    fixData(data) {
      // let noDef = this.$t('tables.undefined')
      for (const d of data) {
        if (!d.district) {
          d.district = {
            name: "",
          };
        }
        if (!d.district.country) {
          d.district.country = {
            name: "",
          };
        }
        if (!d.minister) {
          d.minister = {
            name: "",
          };
        }
      }
      return data;
    },
    apiUrl(params) {
      let route = "mti";
      route += "?page=" + params.page || 0;
      route += "&limit=" + params.perPage || 50;
      if (params.sort && params.sort.field !== "") {
        let field = params.sort.field;
        switch (field) {
          case "district.name":
            field = "Districts.name";
            break;
          case "district.country.name":
            field = "Districts.Countries.name";
            break;
          case "minister.name":
            field = "Ministers.name";
            break;
        }
        route += "&sort=" + field;
        route += "&direction=" + params.sort.type;
      }
      if (params.queries) {
        route += params.queries;
      }

      return route;
    },
    async prepareDownloadTeamStatus(filters) {
      filters["lang"] = this.$i18n.locale;
      const prepareRoute = "mti/downloadMTI";
      const downloadRoute = "mti/downloadMTI/";

      return this.downloadFile(filters, prepareRoute, downloadRoute);
    },
    async updateData(params) {
      params = params || this.serverParams;
      this.loading = true;
      this.error = false;

      let response = false;
      try {
        response = await this.$http.get(this.apiUrl(params));
      } catch (err) {
        this.showToast(this.$t("notifications.network.error"), {
          icon: "fa-times",
          position: "top-right",
          duration: 2500,
          fullWidth: false,
        });
        this.loading = false;
        this.error = true;
        return;
      }

      this.tableData = this.fixData(response.data.data);
      this.pagination = response.data.pagination;
      this.loading = false;
    },
    async searchByName(name) {
      if (name === "") return this.updateData();

      let response = false;
      try {
        response = await this.$http.get("mti?q=" + name);
      } catch (err) {
        // console.log('Error searching mti', err)
        this.loading = false;
        return;
      }

      this.tableData = this.fixData(response.data.data);
      this.pagination = response.data.pagination;
      this.loading = false;
    },
    async tryDelete(item) {
      const result = await this.$swal({
        icon: "warning",
        text: this.$t("notifications.confirm.delete"),
        showCancelButton: true,
        confirmButtonText: this.$t("layout.buttons.confirm"),
        cancelButtonText: this.$t("layout.buttons.cancel"),
      });
      if (result.value !== true) return;

      this.loading = true;
      try {
        await this.$http.delete("mti/" + item.id);
      } catch (err) {
        // console.log('Error deleting mti', err)
        this.loading = false;
        return;
      }
      this.updateData();
    },
    async prepareDownload(format) {
      const prepareRoute = "mti/downloadAll";
      const downloadRoute = "mti/downloadAll/";

      return this.downloadFile({ format: format }, prepareRoute, downloadRoute);
    },
    async downloadFile(filters, prepareRoute, downloadRoute) {
      let fileName = "";
      const prepare = {
        icon: "info",
        title: this.$t("notifications.download.prepare"),
        confirmButtonText: this.$t("notifications.download.button"),
        text: this.$t("notifications.download.wait"),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false;
          try {
            data = await this.$http.post(prepareRoute, filters);
          } catch (e) {
            this.$swal.insertQueueStep(error);
            return;
          }

          fileName = data.data.data;
          this.$swal.insertQueueStep(download);
        },
      };
      const error = {
        icon: "error",
        title: "Error",
        text: this.$t("notifications.download.error"),
      };
      const download = {
        icon: "success",
        title: this.$t("notifications.download.downloading"),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading();
          let response = false;
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: "blob",
            });
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry]);
            return;
          }

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();

          this.$swal.close();
        },
      };
      const retry = {
        icon: "error",
        title: "Error",
        text: this.$t("notifications.download.failed"),
        confirmButtonText: this.$t("notifications.download.retry"),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download);
        },
      };

      this.$swal.queue([prepare]);
    },
  },
};
</script>
